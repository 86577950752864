<template>
  <div class="query-container">
    <div class="query-form">
      <div class="query-item">
        <span class="title">时间：</span>
        <el-date-picker
          v-model="query.payTime"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="date-input"
        >
        </el-date-picker>
      </div>
      <div class="query-item">
        <span class="title">金额：</span>
        <el-input-number
          v-model="query.minMoney"
          :precision="2"
          :step="1"
          :min="0"
          :controls="false"
          class="money-input"
        ></el-input-number>
        <span style="margin: 0 8px">至</span>
        <el-input-number
          v-model="query.maxMoney"
          :precision="2"
          :step="1"
          :min="0"
          :controls="false"
          class="money-input"
        ></el-input-number>
      </div>
      <div class="query-item label-select">
        <span class="title">捐赠人类型：</span>
        <span
          class="label"
          :class="{ active: query.payPeopleType === item.value }"
          v-for="item in payPeopleTypes"
          :key="item.value"
          @click="query.payPeopleType = item.value"
          >{{ item.label }}</span
        >
      </div>
      <div class="query-item label-select">
        <span class="title">支付方式：</span>
        <span
          class="label"
          :class="{ active: query.payType === item.value }"
          v-for="item in payTypes"
          :key="item.value"
          @click="query.payType = item.value"
          >{{ item.label }}</span
        >
      </div>
      <div class="query-item">
        <el-row>
          <el-col :span="14" :xs="24">
            <span class="title">关键字：</span>
            <el-input
              placeholder="捐赠人或其他关键字"
              clearable
              class="search"
              v-model="query.payName"
              :maxlength="20"
            >
            </el-input>
          </el-col>
          <el-col :span="10" :xs="24">
            <el-button class="query-btn" @click="handleQuery">查询</el-button>
            <el-button class="reset-btn" @click="handleReset">重置</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-row class="query-header">
      <el-col :span="6">时间 </el-col>
      <el-col :span="6">金额</el-col>
      <el-col :span="6">捐赠人</el-col>
      <el-col :span="6">支付方式</el-col>
    </el-row>
    <div class="link"></div>
    <div class="loading" v-if="isLoading">
      <span>数据加载中...</span>
    </div>
    <div class="noMatch" v-if="showNoMatch">
      <span>没有搜索到匹配结果</span>
    </div>
    <el-row class="query-row" v-for="(item, index) in list" :key="index">
      <el-col :span="6">{{ item.payTime }} </el-col>
      <el-col :span="6">{{ item.payMoney }}</el-col>
      <el-col :span="6">{{
        item.payAnonymity === "是" ? "匿名" : item.payName
      }}</el-col>
      <el-col :span="6">{{ item.payType }}</el-col>
    </el-row>
    
    <!-- 分页 -->
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      list: [],
      payTypes: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "微信",
          value: "S_IN0",
        },
        {
          label: "支付宝",
          value: "S_IN1",
        },
        {
          label: "线下",
          value: "S_IN2",
        },
      ],
      payPeopleTypes: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "个人",
          value: "S_IN0",
        },
        {
          label: "企业",
          value: "S_IN1",
        },
      ],
      query: {
        payName: "",
        payType: "all",
        payPeopleType: "all",
        payTime: null,
        minMoney: 0,
        maxMoney: 0,
      },
      condition: "||",
      startPay: 0,
      endPay: 0,
      showNoMatch: false,
      isLoading: false
    };
  },
  created() {
    this.getDonate();
  },
  methods: {
    getDonate() {
      this.isLoading = true
      this.$axios({
        method: "post",
        url: "/laf/laf/lafPay/tableList.ajax",
        data: {
          pageNo: this.pageIndex,
          pageSize: this.pageSize,
          orderField: "payTime",
          orderCondition: "desc",
          // condition: "payState:S_IN0||",
          condition:
            this.condition === "||"
              ? "payState:S_IN0||"
              : `payState:S_IN0|${this.condition}`,
          // condition: `payName:@${this.query.payName}|payState:S_IN0||`,
          // condition: this.condition + "payState:S_IN0||",
          startPay: this.startPay,
          endPay: this.endPay,
          // | +"payState:S_IN0||"
        },
      })
        .then((res) => {
          this.list = res.rows;
          this.total = res.totalRows;
          this.isLoading = false
          if(this.list.length){
            this.showNoMatch = false
          }else{
            this.showNoMatch = true
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleQuery() {
      this.isSelect = true
      this.condition = "";
      if (this.query.payName !== "")
        this.condition = `payName:@${this.query.payName}|`;
      if (this.query.payType !== "all")
        this.condition += `payType:${this.query.payType}|`;
      if (this.query.payTime && this.query.payTime.length === 2)
        this.condition += `payTime:D>${this.query.payTime[0]}|payTime:D<${this.query.payTime[1]}|`;
      if (this.query.payPeopleType !== "all")
        this.condition += `payPeopleType:${this.query.payPeopleType}|`;

      // this.condition += `payState:S_IN0|`;
      // if (this.query.minMoney > 0)
      //   this.condition += `payMoney>${this.query.minMoney}|`;
      // if (this.query.maxMoney > 0)
      //   this.condition += `payMoney<${this.query.maxMoney}|`;
      if (this.condition === "") this.condition = "||";
      else this.condition += "|";
      if (this.query.minMoney > 0) this.startPay = this.query.minMoney;
      if (this.query.maxMoney > 0) this.endPay = this.query.maxMoney;
      this.list = []
      this.showNoMatch = false
      this.getDonate();
    },
    handleReset() {
      this.list = []
      this.showNoMatch = false
      this.query = {
        payName: "",
        payType: "all",
        payPeopleType: "all",
        payTime: null,
        minMoney: 0,
        maxMoney: 0,
      };
      this.condition = "||";
      this.pageIndex = 1;
      this.startPay = 0;
      this.endPay = 0;
      this.getDonate();
    },
    handlePageChange(p) {
      this.pageIndex = p;
      this.getDonate();
    },
  },
};
</script>

<style lang='scss' scoped>
.query-form {
  padding: 24px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  margin-bottom: 30px;
  .query-item {
    margin-bottom: 14px;
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #333;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      margin-right: 20px;
      display: inline-block;
      width: 90px;
      text-align: right;
      @include apply-small {
        width: 96px;
      }
    }
  }
}
.date-input {
  width: 320px;
}
.money-input {
  width: 140px;
}
.label-select {
  .label {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    margin-right: 40px;
    cursor: pointer;
    border-radius: 4px;
    @include apply-small {
      margin-right: 6px;
    }
    &:hover,
    &.active {
      background: #cd242a;
      color: #fff;
    }
  }
}
.search {
  width: 250px;
}
.query-btn {
  width: 90px;
  height: 35px;
  background: #cd242a;
  border-radius: 2px;
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 6px;
  @include apply-small {
    margin-left: 120px;
    margin-top: 16px;
  }
}

.reset-btn {
  width: 90px;
  height: 35px;
  background: #ff9900;
  border-radius: 2px;
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #ff9900;
  }
}

.query-header {
  font-size: 16px;
  color: #c40000;
  ::v-deep .el-col {
    text-align: center;
  }
}
.link {
  height: 1px;
  margin-top: 9px;
  margin-bottom: 21px;
  border-bottom: 1px solid #cd242a;
}
.query-row {
  margin-bottom: 15px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  &:hover {
    background: #f8f8f8;
  }
  ::v-deep .el-col {
    text-align: center;
    height: 100%;
  }
}
.fenYe {
  text-align: center;
  margin-top: 30px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
.noMatch{
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
.loading{
  padding-top: 30px;
  text-align: center;
  color: #999;
  font-size: 20px;
}
</style>